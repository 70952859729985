import {
    required,
    helpers
  } from 'vuelidate/lib/validators';
  
  import { textareaSpecials, } from '../funciones';
  
  const alphaNumCustom = (value) => /^[\w\u00f1\u00d1\-]+$/g.test(value);
  const TextareaNotReq = (value) => !helpers.req(value) || textareaSpecials(value);
  const correctDate = (value) => {
    if (value === '') {
      return true;
    } else {
      let i = value.indexOf('-');
      let j = value.indexOf('/');
      if (i !== -1) {
        let date = value.split('-' || '/');
        let fecha = new Date();
        let ano = fecha.getFullYear();
        ano = parseInt(ano) + 10;
        if (date.length === 3) {
          if (
            (date[0].length > 0 && date[0].length < 4) ||
            (date[2].length > 0 && date[2].length < 4)
          ) {
            return true;
          } else if (
            (parseInt(date[0]) > 2010 && parseInt(date[0]) < ano) ||
            (parseInt(date[2]) > 2010 && parseInt(date[2]) < ano)
          ) {
            return true;
          } else if (
            parseInt(date[1]).length > 0 &&
            parseInt(date[1]).length < 12
          ) {
            return true;
          } else if (
            (parseInt(date[2]).length > 0 && parseInt(date[2]).length <= 31) ||
            (parseInt(date[0]).length > 0 && parseInt(date[0]).length <= 31)
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else if (j !== -1) {
        let date = value.split('/');
        let fecha = new Date();
        let ano = fecha.getFullYear();
        ano = parseInt(ano) + 10;
        if (date.length === 3) {
          if (
            (date[0].length > 0 && date[0].length < 4) ||
            (date[2].length > 0 && date[2].length < 4)
          ) {
            return true;
          } else if (
            (parseInt(date[0]) > 2010 && parseInt(date[0]) < ano) ||
            (parseInt(date[2]) > 2010 && parseInt(date[2]) < ano)
          ) {
            return true;
          } else if (
            parseInt(date[1]).length > 0 &&
            parseInt(date[1]).length < 12
          ) {
            return true;
          } else if (
            (parseInt(date[2]).length > 0 && parseInt(date[2]).length <= 31) ||
            (parseInt(date[0]).length > 0 && parseInt(date[0]).length <= 31)
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }
  };
  
  export default () => {
    return {
      inventoryInfo: {
        BillOfLadingYardId: {},
        ClientTpId: { required },
        NroBl: { required, alphaNumCustom },
        YardJson: { },
        CommodityId: { },
        GeneralInfo: { TextareaNotReq },
        CertificateDate: { correctDate },
        Status: {},
      },
    }
  }